/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import focusLock from 'dom-focus-lock';
import AOS from 'aos';
import ResponsiveAutoHeight from 'responsive-auto-height';

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease'
});

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Same height
if (document.querySelectorAll('.same-height').length) {
	new ResponsiveAutoHeight('.same-height');
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Home slider 
var slider = document.querySelectorAll('.slider-block .swiper-container');
if (slider.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var swiper = new Swiper(".slider-block .swiper-container", {
			slidesPerView: '1',
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + "</span>";
				}
			}
		});
	})();
}

// Modal slider
Array.from(document.querySelectorAll('.growers-block .swiper-container')).forEach(function (modal) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var modalSwiper = new Swiper(modal, {
			slidesPerView: '1',
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			allowTouchMove: false
		});
		document.querySelectorAll('.growers-div').forEach(item => {
			item.addEventListener('click', event => {
				if (modal) {
					modal.classList.remove("hide-modal");
					var slideId = item.id;
					modalSwiper.slideTo(slideId, 0);
				}
			})
		})
		document.addEventListener('keydown', e => {
			if (e.keyCode === 27) {
				modal.classList.add("hide-modal");
			}
		});
		document.addEventListener('click', e => {
			if (e.target.classList.contains("swiper-slide")) {
				e.target.parentElement.parentElement.classList.add("hide-modal");
			}
			else if (e.target.classList.contains("slide-close")) {
				e.target.parentElement.parentElement.parentElement.parentElement.classList.add("hide-modal");
			}
		});
	})();
});

// Close menu on backdrop click, anchor click or escape key
document.querySelectorAll('.menu-checkbox').forEach(function (dropdown) {
	if (dropdown) {
		document.addEventListener('click', e => {
			if (!dropdown.contains(e.target) && (dropdown.checked) && (e.target.classList.contains("hamburger-inner") == false) && (e.target.classList.contains("hamburger") == false) && (e.target.classList.contains("hamburger-box") == false)) {
				toggle(false);
			}
		});

		var elements = document.getElementsByClassName("menu-link");

		for (var i = 0; i < elements.length; i++) {
			elements[i].style.transitionDelay = i * 100 + "ms";
			elements[i].addEventListener('click', e => {
				toggle(false);
			});
		}

		const toggle = checked => {
			dropdown.checked = checked;

			if (checked) {
				focusLock.on(dropdown);
			} else {
				focusLock.off(dropdown);
			}
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});
	}
});

// Close dropdown in textblock on backdrop click or escape key
document.querySelectorAll('.dropdown-checkbox').forEach(function (dropdown) {
	if (dropdown) {
		document.addEventListener('click', e => {
			if (!dropdown.contains(e.target) && (dropdown.checked) && (e.target.classList.contains("dropdown-label") == false)) {
				toggle(false);
			}
		});
		const toggle = checked => {
			dropdown.checked = checked;

			if (checked) {
				focusLock.on(dropdown);
			} else {
				focusLock.off(dropdown);
			}
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});
	}
});

// Counter for growers

// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration);
// An ease-out function that slows the count as it progresses
const easeOutQuad = t => t * (2 - t);

var growersInfo = document.querySelector('.growers-information-block');
if (growersInfo) {

	var countersLoaded = false;

	var growersInfoHeight = growersInfo.clientHeight;// listen for scroll event and call animate function
	document.addEventListener('scroll', animate);
	animate();

	// animate element when it is in view
	function animate() {
		if (growersInView()) {
			if (!countersLoaded) {
				growersInfo.querySelectorAll('.grower-title span').forEach(function (title) {
					if (title) {
						let frame = 0;
						const countTo = parseInt(title.innerHTML, 10);
						// Start the animation running 60 times per second
						const counter = setInterval(() => {
							frame++;
							// Calculate our progress as a value between 0 and 1
							// Pass that value to our easing function to get our
							// progress on a curve
							const progress = easeOutQuad(frame / totalFrames);
							// Use the progress value to calculate the current count
							const currentCount = Math.round(countTo * progress);

							// If the current count has changed, update the element
							if (parseInt(title.innerHTML, 10) !== currentCount) {
								title.innerHTML = currentCount + " ";
							}

							// If we’ve reached our last frame, stop the animation
							if (frame === totalFrames) {
								clearInterval(counter);
							}
						}, frameDuration);
					}
				});
			}
			countersLoaded = true;
		}
	}

	// check if footer is in view
	function growersInView() {
		// get window height
		var windowHeight = window.innerHeight;
		// get number of pixels that the document is scrolled
		var scrollY = window.scrollY || window.pageYOffset;

		// get current scroll position (distance from the top of the page to the bottom of the current viewport)
		var scrollPosition = scrollY + windowHeight;
		// get element position (distance from the top of the page to the bottom of the element)
		var elementPosition = growersInfo.getBoundingClientRect().top + scrollY + growersInfoHeight - growersInfo.clientHeight;

		// is scroll position greater than element position? (is element in view?)
		if (scrollPosition > elementPosition) {
			return true;
		}
		return false;
	}
}

